import Layout from "../components/layout"
import React from "react"
import { Row, Col } from "styled-bootstrap-grid"
import {
  Paragraph1,
  Paragraph2,
  Part2,
  Title,
  AboutContent,
  AboutWrapper,
} from "../styles/about/style"

const About = () => {
  return (
    <Layout>
      <AboutWrapper>
        <AboutContent>
          <Title>About Us</Title>
          <Paragraph1>
            Advisors to the banking industry on branch transformation.{" "}
            <Part2>
              Offering consultancy on individual issues within the branch
              network or managing the development and design of a new brand or
              retail concept from start to finish.
            </Part2>
          </Paragraph1>
          <Row>
            <Col md={6}>
              <Paragraph2 right={"2.5rem"}>
                Having worked with over 350 banks worldwide over the last 30
                years, we can provide unique advice on digital and physical
                branch networks to maximize the customer journey in the most
                cost-effective way.
              </Paragraph2>
            </Col>
            <Col md={6}>
              <Paragraph2 left={"2.5rem"}>
                We can also create exciting branch environments that builds on
                the banks brand image and values providing a strong customer
                relationship.
              </Paragraph2>
            </Col>
          </Row>
        </AboutContent>
      </AboutWrapper>
    </Layout>
  )
}

export default About

